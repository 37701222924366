import styled from 'styled-components'

export const Wrapper = styled.div`
  max-width: 1200px;
  display: block;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 100px;

  h5 {
    svg {
      opacity: .8;
      transform: scale(1);
      animation: float 1s infinite;
      color: #d2a420;

      @keyframes float {
        0% {
          padding-top: 10px;
        }
  
        50% {
          padding-top: 2px;
        }
  
        100% {
          padding-top: 0px;
        }
      }
    }
  }

  h2 {
    display: block;
    width: 100%;
    text-align: center;
    justify-content: center;
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: #d8b55c;
    text-shadow: 0 0 9px #976720;
  }
`

export const ListProducts = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
  margin: 0 auto;
  padding: 20px;

  h2 {
    font-weight: bold;
  }

  @media (max-width: 1085px) {
    grid-template-columns: repeat(3, 1fr);
    display: inherit;
  }
  @media (max-width: 867px) {
    grid-template-columns: repeat(2, 1fr);
    display: inherit;
  }
  @media (max-width: 578px) {
    grid-template-columns: repeat(1, 1fr);
    display: inherit;
  }
`
export const Product = styled.div`
  display: inline-grid;
  background: #FFFFFF;
  border-radius: 50px 50px 10px 50px;
  border: 4px solid #d3cece;
  max-width: 250px;
  cursor: pointer;
  transition: 0.2s;
  margin-top: 20px;
  padding: 10px;
  margin-right: 15px;
  box-shadow: 10px 12px 20px -12px rgba(255, 255, 255,0);

  img {
    width: 100%;
    display: block;
    transition: 0.4s;
    padding: 10px;
  }

  h4 {
    margin-top: 10px;
    color: #010109;
    font-weight: bold;
    opacity: .8;
  }

  span {
    background: #323232;
    padding: 10px;
    border-radius: 50px 50px 10px 50px;
    font-size: 14px;
    margin-top: 20px;
    transition: 0.2s;
    display: flex;
    text-align: center;
    justify-content: center;
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
    max-height: 45px;
    border: 3px solid #323232;
    transition: 0.2s;
    text-transform: uppercase;
    font-weight: bold;
    
    &:hover {
      border-radius: 10px 50px 50px 50px;
      transition: 0.2s;
    }
  }

  &:hover {
    transition: 0.2s;
    box-shadow: 10px 12px 20px -12px rgba(255, 255, 255,.6);

    h4 {
      opacity: 1;
      color: #010109;
    }

    img {
      transition: 0.6s;
      padding: 0px;
    }
  }
`

export const Footer = styled.div`
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
  color: #FFFFFF;
  justify-content: space-between;
  border-top: 1px solid #252525;
  padding-top: 20px;
  padding-bottom: 30px;
  align-items: center;
  padding: 15px;
  margin-top: 50px;

  p {
    font-size: 12px;
  }

  img {
    opacity: .5;
    width: 120px;
    transition: 0.2s;
    
    &:hover {
      transition: 0.2s;
      opacity: .8;
    }
  }

`
