import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root {
    --background: #000000;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  body {
    background: var(--background);
    -webkit-font-smoothing: antialiased;
  }

  body, input, textarea, button {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 600;
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowned;
  }

  body::-webkit-scrollbar {
    width: 8px;
  }

  body {
    scrollbar-width: thin;
    scrollbar-color: #010109 #010109;
  }

  body::-webkit-scrollbar-track {
    background: #f0f2f5;
  }

  body::-webkit-scrollbar-thumb {
    background-color: #010109;
    border: 3px solid #010109;
  }

  body::-webkit-scrollbar-track {
    background: transparent;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  textarea {
    background: #1a1919;
    border: none;
    border-radius: 5px;
    padding: 10px;
    color: #f1f1f1;
    min-width: 100%;

    &:focus-visible {
      outline: 0;
    }
  }

  .ReactModal__Content {
    h2 {
      margin-bottom: 30px;
    }
  }

  .ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.5) !important;
    z-index: 1;
  }

  .ReactModal__Content--after-open {
    border-radius: 10px !important;
    border: 4px solid #d3cece !important;

    @media (max-width: 375px) {
      padding: 10px !important;

      img {
        max-width: 250px !important;
      }
    }

    @media (max-width: 530px) {
      img {
        max-width: 300px !important;
      }
    }

    img {
      max-width: 500px;
      display: flex;
      margin: 0 auto;
    }

    h3 {
      font-size: 20px;
      color: #010109;
      font-weight: 200;
      opacity: .9;

      svg {
        &:first-child {
          margin-left: 10px;
        }
      }
    }

    h4 {
      font-size: 14px;
      font-weight: 200;
      color: #010109;
      opacity: .8;  
      max-width: 400px;
      text-align: justify;
    }

    button {
      background: transparent;
      display: flex;
      justify-content: center;
      color: #323232;
      border-radius: 25px;
      border: 2px solid #323232;
      transition: 0.2s;right: 0;
      position: absolute;
      margin-right: 5px;
      margin-top: 5px;
      z-index: 9999;
      
      &:hover {
        transition: 0.2s;
        background: #323232;
        color: #ffffff;
      }
    }
  }

  /* .react-switch-bg {
    background: #12a93f !important;
  } */
`
