import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const ModalSpan = styled.span`
  h3 {
    padding: 15px;
  }
`

export const ModalContent = styled.div`
  position: absolute;
`

export const Wrapper = styled.div`
  display: block;
  margin-top: 78px;
  max-width: 100%;

  img {
    max-width: 100%;
  }

  @media (max-width: 1085px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 867px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 578px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .react-slideshow-container .default-nav {
    background: transparent;
    opacity: .6;
  }
`

export const About = styled.div`
  display: block;
  max-width: 800px;
  color: #FFFFFF;
  text-align: center;
  margin: 0 auto;
  padding: 30px;
  align-items: center;

  h2 {
    font-size: 35px;
    color: #ffffff;
    text-shadow: 0 0 4px rgb(234 234 234 / 60%);

    span {
      font-weight: 200;
    }
  }

  p {
    display: block;
    width: 100%;
    margin-top: 30px;
    font-weight: 200;
    line-height: 1.80;

    span {
      font-size: 16px;
      color: #ffffff !important;
      text-shadow: 0 0 4px rgb(234 234 234 / 60%);
      font-weight: 200;
    }
  }
`

export const Footer = styled.div`
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
  color: #FFFFFF;
  justify-content: space-between;
  border-top: 1px solid #252525;
  padding-top: 20px;
  padding-bottom: 30px;
  align-items: center;
  padding: 15px;

  div {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    a {
      display: flex;
      color: #ffffff;
      opacity: .5;
      transition: 0.2s;
      
      &:hover {
        opacity: .9;
        transition: 0.2s;
      }
    }
  }

  p {
    font-size: 12px;
  }

  img {
    opacity: .5;
    width: 120px;
    transition: 0.2s;
    
    &:hover {
      transition: 0.2s;
      opacity: .8;
    }
  }
`

export const CompleteLine = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  margin-top: -15px;
  margin-bottom: 30px;
  padding: 30px;
  transition: 0.2s;

  a {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
  }

  h2 {
    font-weight: bold;
    font-size: 25px;
    color: #ffffff;
    display: flex;
    align-items: center;
  }

  &:hover {
    transition: 0.2s;
    opacity: .8;
  }

  @media (max-width: 578px) {
    margin-top: -35px;

    h2 {
      font-size: 15px;
    }
  }
`

export const PromoContent = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin: 0 auto;

  img {
    border-radius: 5px;
  }

  @media (max-width: 1085px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 867px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 578px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const IsoContent = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
  
  img {
    max-width: 110px;
    max-height: 110px;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 0 auto;
    opacity: .6;
    transition: 0.2s;
    
    &:hover {
      transition: 0.2s;
      opacity: 1;
    }
  }

  @media (max-width: 1085px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 867px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 578px) {
    grid-template-columns: repeat(2, 1fr);

    img {
      max-width: 90px;
      max-height: 90px;
    }
  }
`

export const Club = styled.div`
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;

  @media (max-width: 754px) {
    grid-template-columns: repeat(1, 1fr);
    padding-right: 20px;
    padding-left: 20px;
  }
`

export const Subscribe = styled.div`
  width: 100%;
  display: block;
  justify-content: center;
  padding-right: 10px;
  background: rgb(39, 39, 39, .3);
  border-radius: 0px 15px 15px 0px;
  padding: 30px;

  h3 {
    color: #ffffff;
    display: block;
    width: 100%;
    font-size: 25px;
    text-align: right;
    text-shadow: 0 0 4px rgb(234 234 234 / 60%);
    padding-right: 10px;
  }
  
  p {
    color: #ffffff;
    display: block;
    width: 100%;
    text-align: right;
    padding-right: 10px;
  }

  input {
    margin-top: 10px;
    max-width: 400px;
  }

  @media (max-width: 754px) {
    border-radius: 15px 15px 15px 15px;

    h3, p {
      text-align: center;
    }
  }
`

export const FormContent = styled.div`
  width: 100%;
  display: block;
  justify-content: right;

  span, h4 {
    display: block;
    max-width: 400px;
    background: #ff5c5c;
    color: #ffffff;
    font-size: 14px;
    padding: 5px;
    margin-left: auto;
    margin-right: 10px;
    border-radius: 20px 20px 3px 20px;
    text-align: center;
    margin-top: 10px;
  }

  h4 {
    background: rgb(4, 211, 97);
    color: #2b2b2c;
  }

  @media (max-width: 754px) {
    justify-content: center;
    max-width: 557px;
  }
`

export const SendButtonContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  padding-right: 10px;

  button {
    color: #ffffff;
    background: #0086ef;
    padding: 10px;
    border-radius: 50px 50px 10px 50px;
    font-size: 14px;
    margin-top: 20px;
    transition: 0.2s;
    display: block;
    justify-content: right;
    width: 200px;
    margin-top: 20px;
    max-height: 45px;
    border: 3px solid #0086ef;
    transition: 0.2s;
    box-shadow: 0px 8px 38px -8px rgb(0, 134, 239, .7);
    
    &:hover {
      border-radius: 10px 50px 50px 50px;
      transition: 0.2s;
      box-shadow: 0px 8px 65px -6px #0086ef;
    }
  }
`

interface ButtonElementProps {
  readonly loading: boolean;
};

export const ButtonElement = styled.button.attrs((props: any) => ({
  type: 'button',
  disabled: props.loading ? true : false,
}))<ButtonElementProps>`
  color: #ffffff;
  background: #0086ef;
  padding: 10px;
  border-radius: 50px 50px 10px 50px;
  font-size: 14px;
  margin-top: 20px;
  transition: 0.2s;
  display: block;
  justify-content: right;
  width: 200px;
  margin-top: 20px;
  max-height: 45px;
  border: 3px solid #0086ef;
  transition: 0.2s;
  box-shadow: 0px 8px 38px -8px rgb(0, 134, 239, .7);

  opacity: ${(props: any) => props.loading ? .5 : 1};
  cursor: ${(props: any) => props.loading ? 'not-allowed' : 'pointer'};
  
  &:hover {
    border-radius: 10px 50px 50px 50px;
    transition: 0.2s;
    box-shadow: 0px 8px 65px -6px #0086ef;
  }

  svg {
    animation: ${rotate} 1s linear infinite;
  }
`

export const ProductsImage = styled.img`
  width: 100%;
  margin-top: -55px;
  margin-bottom: 15px;
`