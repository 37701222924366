import React from 'react'
import { MdAssessment, MdLogout } from 'react-icons/md'
import { FaWhatsapp } from 'react-icons/fa'
import { Link } from 'react-router-dom';
import { Content, Wrapper, IconContent, MenuContent, WhatsAppButton, LanguageContent, LanguageItem, LiUserLogged, UserLogged } from './styles'
import logo from '../../assets/proflex/logo-dourada.png'
import brazil from '../../assets/brazil.png'
import spain from '../../assets/spain.png'
import usa from '../../assets/estados-unidos.png'
import { LanguagesContext } from '../../contexts/languages';
import { ActiveMenuContext } from '../../contexts/activeMenu';

import pt from '../../languages/pt'
import en from '../../languages/en'
import es from '../../languages/es'
import { AuthContext } from '../../contexts/auth';

export function Header({ isLogged }:{ isLogged?: boolean }) {
  const { language, setLanguage } = React.useContext(LanguagesContext)
  const { activeMenu, setActiveMenu } = React.useContext(ActiveMenuContext)
  const { auth, handleLogout } = React.useContext(AuthContext)

  let currentLang = language

  switch(language) {
    case 'pt':
      currentLang = pt
      break;
    case 'es':
      currentLang = es
      break;
    case 'en':
      currentLang = en
      break;
  }

  const whats = [
    'https://wa.me/5511983887968',
    'https://wa.me/32460209677',
    'https://wa.me/353833339172',
    'https://wa.me/5511986325609',
    'https://wa.me/555189204315',
    'https://wa.me/555399894631',
    'https://wa.me/5511978659901',
    'https://wa.me/5511984545996',
    'https://wa.me/595991615834',
    'https://wa.me/5511917086459',
  ]

  const random = Math.floor(Math.random() * whats.length);

  return (
    <Wrapper>
      <Content>
        {isLogged && !auth.admin ? 
          <>
            <Link onClick={() => setActiveMenu('home')} to='/shop/my-dash'><img src={logo} alt='ProFlex Pharmaceuticals' /></Link>
            <MenuContent>
              <LiUserLogged><Link to='/shop/my-dash'><MdAssessment size={30} /></Link></LiUserLogged>
            </MenuContent>
          </> :
          <>
            <Link onClick={() => setActiveMenu('home')} to='/'><img src={logo} alt='ProFlex Pharmaceuticals' /></Link>
            <MenuContent>
              <li onClick={() => setActiveMenu('home')}><Link to='/'>{activeMenu === 'home' ? <span>{currentLang.menu.home}</span> : currentLang.menu.home}</Link></li>
              <li onClick={() => setActiveMenu('products')}><Link to='/products'>{activeMenu === 'products' ? <span>{currentLang.menu.products}</span> : currentLang.menu.products}</Link></li>
              <li onClick={() => setActiveMenu('contact')}><Link to='/link-contact'>{activeMenu === 'contact' ? <span>{currentLang.menu.contact}</span> : currentLang.menu.contact}</Link></li>
            </MenuContent>
          </>
        }
        <IconContent>
        {isLogged && !auth.admin ?
          <UserLogged>
            <h4>{auth.name.replaceAll('"', "")}</h4>
            <Link onClick={() => handleLogout()} to='#'><MdLogout size={20} /> Sair</Link>
          </UserLogged> :
          <>
            <LanguageContent>
              <LanguageItem><img src={brazil} alt='Brazil' onClick={() => setLanguage('pt')} /></LanguageItem>
              <LanguageItem><img src={spain} alt='Español' onClick={() => setLanguage('es')} /></LanguageItem>
              <LanguageItem><img src={usa} alt='English' onClick={() => setLanguage('en')} /></LanguageItem>
            </LanguageContent>
            <a target='_blank' href={whats[random]} rel="noopener noreferrer"><WhatsAppButton>{currentLang.wppButton} <FaWhatsapp size={24} /></WhatsAppButton></a>
          </>
        }
        </IconContent>
      </Content>
    </Wrapper>
  )
}
