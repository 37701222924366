import React from 'react';
import { GlobalStyle } from './styles/global';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import { Header } from './components/Header';
import { LinkContact } from './components/LinkContact';
import { Main } from './components/Main';
import { Products } from './components/Products';

import history from './history';
import { AuthContext } from './contexts/auth';

function CustomRoute({ isPrivate, isPrivateCustomer, ...rest } : any) {
  const { auth, loadingLogin } = React.useContext(AuthContext)

  if (loadingLogin) {
    return <></>;
  }

  if (isPrivate && !auth.authenticated) {
    return <Redirect to='/' />
  }

  if (isPrivate && !auth.admin) {
    return <Redirect to='/' />
  }
  
  if (isPrivateCustomer && !auth.authenticated) {
    return <Redirect to='/admin/login' />
  }

  return <Route { ...rest } />
}

function App() {
  return (
    <>
      <Router history={history}>
        <Switch>
          <CustomRoute exact path='/'>
            <Header />
            <Main />
          </CustomRoute>
          <CustomRoute exact path='/link-contact'>
            <Header />
            <LinkContact />
          </CustomRoute>
          <CustomRoute exact path='/products'>
            <Header />
            <Products />
          </CustomRoute>
        </Switch>
        <GlobalStyle />
      </Router>
    </>
  );
}

export default App;
