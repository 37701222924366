const ptBR = {
  menu: {
    home: 'Início',
    products: 'Produtos',
    contact: 'Contato',
  },
  wppButton: 'Falar com Vendedor',
  about: 'A ProFlex Pharmaceuticals, é uma Indústria Farmacêutica que desenvolve produtos da mais alta qualidade para atletas de alta performance que buscam grandes resultados em seus esportes. Somos uma grande empresa Norte Americana com mais de 10 anos de mercado, que agora conta com parte de suas instalações no Paraguay. Nosso maior objetivo, é proporcionar a mais alta tecnologia aliada a ciência para que cada cliente conquiste resultados incríveis e imensuráveis. Seja bem-vindo ao TEAM PROFLEX®️ WELCOME THE TOP 🇺🇸',
  completeLine: 'Conheça nossa linha completa',
  titles: {
    products: 'Nossos produtos',
    contact: 'Entre em Contato',
    restrict: 'Área Restrita',
  },
  subtitles: {
    club: 'Tenha acesso a conteúdos exclusivos. Inscreva-se.',
    originals: 'Faça a autenticação do seu produto através do QR Code.',
  },
  buttons: {
    viewDetails: 'Ver detalhes',
  },
  formContact: {
    name: 'Nome Completo *',
    phone: 'Celular *',
    email: 'E-mail *',
    subject: 'Assunto *',
    message: 'Mensagem *',
    send: 'Enviar',
    login: 'Entrar',
  },
  shop: {
    buy: 'Adicionar',
    qty: 'Quantidade de Produtos',
    myItems: 'Meus Itens',
    menu: {
      dash: 'Meu Perfil',
      products: 'Comprar',
    },
    welcome: 'Bem-Vindo'
  }
}

export default ptBR;