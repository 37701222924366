import React, { useState, useEffect } from 'react'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdClose } from "react-icons/md";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { 
  Wrapper, About, Footer, CompleteLine, IsoContent, 
  Club, Subscribe, FormContent,
  SendButtonContent, ButtonElement, ProductsImage, ModalContent, ModalSpan } from "./styles";
import { Slide } from 'react-slideshow-image';
import { Link } from 'react-router-dom';
import 'react-slideshow-image/dist/styles.css';
import slide01 from '../../assets/proflex/banner01.jpg';
import slide02 from '../../assets/proflex/banner02.jpg';
import slide03 from '../../assets/proflex/banner03.jpg';
import logo from '../../assets/proflex/logo-dourada.png';
import { LanguagesContext } from '../../contexts/languages';
import { ActiveMenuContext } from '../../contexts/activeMenu';
import { Input } from '../Input';
import allProducts from '../../assets/proflex/all-products.png';
import useQuery from '../../utils/queryParams';
import productOriginal from '../../assets/product_original.png';
import Modal from 'react-modal'

import iso01 from '../../assets/iso-1.png';
import iso02 from '../../assets/iso-2.png';
import iso03 from '../../assets/iso-3.png';
import iso04 from '../../assets/iso-4.png';

import pt from '../../languages/pt'
import en from '../../languages/en'
import es from '../../languages/es'

import api from '../../services/api'

export function Main() {
  const { setActiveMenu } = React.useContext(ActiveMenuContext)
  const { language } = React.useContext(LanguagesContext)
  const [lead, setLead] = useState({
    name: '',
    email: '',
    phone: '',
  })
  const [leadError, setLeadError] = useState(false);
  const [leadSendError, setLeadSendError] = useState(false);
  const [leadSuccess, setLeadSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verifyQr, setVerifyQr] = useState('');
  let currentLang = language
  let query = useQuery()

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '0'
    }
  };

  switch(language) {
    case 'pt':
      currentLang = pt
      break;
    case 'es':
      currentLang = es
      break;
    case 'en':
      currentLang = en
      break;
  }

  const slideImages = [
    {
      url: slide03,
      caption: 'ProFlex Pharmaceuticals'
    },
    {
      url: slide02,
      caption: 'ProFlex Pharmaceuticals'
    },
    {
      url: slide01,
      caption: 'ProFlex Pharmaceuticals'
    },
  ];

  useEffect(() => {
    const verifyQr2: any = query.get('verifyQr')

    if (verifyQr2 === process.env.REACT_APP_QR_VERIFY_CODE || verifyQr2 === null) {
      setVerifyQr(verifyQr2)
    } else {
      setVerifyQr('not-original')
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
    <Wrapper>
      <Slide duration={4000} prevArrow={<MdKeyboardArrowLeft size={25} color='#FFFFFF' />} nextArrow={<MdKeyboardArrowRight size={25} color='#FFFFFF' />}>
        {slideImages.map((slideImage, index) => {
          return ( <div className="each-slide" key={index}>
            <img src={slideImage.url} alt={slideImage.caption} />
          </div> )
        })}
      </Slide>
      <About>
        <h2>ProFlex Pharmaceuticals<span>®</span></h2>
        <p>A ProFlex Pharmaceuticals, é uma Indústria Farmacêutica que desenvolve produtos da mais <span>alta qualidade</span>, para atletas de <span>alta performance</span>, que buscam grandes resultados em seus esportes.</p>

        <p>Somos uma grande empresa Norte Americana com mais de <span>10 anos de mercado</span>, que agora conta com parte de suas instalações no Paraguay.</p>

        <p>Nosso maior objetivo, é proporcionar a mais <span>alta tecnologia</span> aliada a ciência para que cada cliente conquiste <span>resultados incríveis</span> e imensuráveis.</p>
        <p><span>Seja bem-vindo ao TEAM PROFLEX PHARMACEUTICALS<br /> WELCOME TO THE ULTRA PURE 🧬</span></p>
      </About>
      <CompleteLine>
        <Link onClick={() => setActiveMenu('products')} to='/products'><h2>{currentLang.completeLine} <MdKeyboardArrowRight size={32} /></h2></Link>
      </CompleteLine>
      <ProductsImage src={allProducts} alt="Todos produtos" />
      <IsoContent>
        <img src={iso01} alt='ISO'/>
        <img src={iso02} alt='ISO'/>
        <img src={iso03} alt='ISO'/>
        <img src={iso04} alt='ISO'/>
      </IsoContent>
      <Footer>
        <div>
          <p>Copyright © {new Date().getFullYear()} ProFlex Pharmaceuticals. All rights reserved.</p>
        </div>
        <div>
          <img src={logo} alt='ProFlex Pharmaceuticals' />
        </div>
      </Footer>
    </Wrapper>
    <ModalContent>
      <Modal
        isOpen={verifyQr ? true : false}
        style={customStyles}
        contentLabel="Sucesso!"
        shouldCloseOnOverlayClick
      >
        <button type="button" onClick={() => setVerifyQr('')}><MdClose size={30}/></button>
        {verifyQr === 'not-original' ? (
          <>
            <ModalSpan>
              <h3>Produto Não Original</h3>
              <h3>Compre um produto original e garanta qualidade ProFlex!</h3>
            </ModalSpan>
          </>
        ) : (
          <img onClick={() => setVerifyQr('')} src={productOriginal} alt='Product Original' />
        )}
      </Modal>
    </ModalContent>
    </>
  )
}