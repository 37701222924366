import React, { useState } from 'react';
import { ListProducts, Wrapper, Product, Footer } from './styles'
import { LanguagesContext } from '../../contexts/languages';
import { MdKeyboardArrowDown, MdClose, MdOutlineStar } from 'react-icons/md';
import logo from '../../assets/proflex/logo-dourada.png';
import Modal from 'react-modal'

import pt from '../../languages/pt'
import en from '../../languages/en'
import es from '../../languages/es'

import acetato from '../../assets/proflex/images/6.png';
import boldenona from '../../assets/proflex/images/1.png';
import deca from '../../assets/proflex/images/2.png';
import durateston from '../../assets/proflex/images/3.png';
import masteron from '../../assets/proflex/images/5.png';
import stanoPot from '../../assets/proflex/images/7.png';
import oxandrolona5 from '../../assets/proflex/images/8.png';
import oxandrolona10 from '../../assets/proflex/images/9.png';
import oxandrolona20 from '../../assets/proflex/images/10.png';
import hemogenin from '../../assets/proflex/images/11.png';
import diana from '../../assets/proflex/images/12.png';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  }
};

const products = [
  {
    id: 1,
    name: 'Boldenona',
    description: 'Solución Oleosa Inyectable. Via Intramuscular profunda. Uso Adulto. Para detalles a cerca de Boldenona ProFlex consulte a bula del producto.',
    image: boldenona
  },
  {
    id: 3,
    name: 'Deca',
    description: 'Solución Oleosa Inyectable. Via Intramuscular profunda. Uso Adulto. Para detalles a cerca de Deca ProFlex consulte a bula del producto.',
    image: deca
  },
  {
    id: 4,
    name: 'Durateston',
    description: 'Solución Oleosa Inyectable. Via Intramuscular profunda. Uso Adulto. Para detalles a cerca de Durateston ProFlex consulte a bula del producto.',
    image: durateston
  },
  {
    id: 6,
    name: 'Masteron',
    description: 'Solución Oleosa Inyectable. Via Intramuscular profunda. Uso Adulto. Para detalles a cerca de Masteron ProFlex consulte a bula del producto.',
    image: masteron
  },
  {
    id: 11,
    name: 'Acetato de Trembolona',
    description: 'Solución Oleosa Inyectable. Via Intramuscular profunda. Uso Adulto. Para detalles a cerca de Acetato de Trembolona ProFlex consulte a bula del producto.',
    image: acetato
  },
  {
    id: 12,
    name: 'Dianabol',
    description: 'Uso Adulto. Para detalles a cerca de Metandrostenolona Oral ProFlex consulte a bula del producto.',
    image: diana
  },
  {
    id: 13,
    name: 'Stanozolol',
    description: 'Uso Adulto. Para detalles a cerca de Stanozolol Oral ProFlex consulte a bula del producto.',
    image: stanoPot
  },
  {
    id: 14,
    name: 'Oxandrolona 5',
    description: 'Uso Adulto. Para detalles a cerca de Oxandrolona 10mg Oral ProFlex consulte a bula del producto.',
    image: oxandrolona5
  },
  {
    id: 15,
    name: 'Oxandrolona 10',
    description: 'Uso Adulto. Para detalles a cerca de Oxandrolona 10mg Oral ProFlex consulte a bula del producto.',
    image: oxandrolona10
  },
  {
    id: 16,
    name: 'Oxandrolona 20',
    description: 'Uso Adulto. Para detalles a cerca de Oxandrolona 20mg Oral ProFlex consulte a bula del producto.',
    image: oxandrolona20
  },
  {
    id: 17,
    name: 'Hemogenin',
    description: 'Uso Adulto. Para detalles a cerca de Oximetolona Oral ProFlex consulte a bula del producto.',
    image: hemogenin
  },
]

export function Products() {
  const [isOpen, setIsOpen] = useState(false)
  const [productModal, setProductModal] = useState({
    id: 0,
    name: '',
    description: '',
    image: '',
  })
  const { language } = React.useContext(LanguagesContext)
  let currentLang = language

  switch(language) {
    case 'pt':
      currentLang = pt
      break;
    case 'es':
      currentLang = es
      break;
    case 'en':
      currentLang = en
      break;
  }

  function openModal(product: any) {
    setProductModal(product)
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Wrapper>
        <h2>{currentLang.titles.products}</h2>
        <h5><MdKeyboardArrowDown size={35} /></h5>
        <ListProducts>
          {products.map(product => {
            return (
              <Product>
                <img onClick={() => openModal(product)} src={product.image} alt={product.name} />
                <h4>{product.name}</h4>
                <span onClick={() => openModal(product)}>{currentLang.buttons.viewDetails}</span>
              </Product>
            )
          })}
        </ListProducts>
        <Footer>
          <p>Copyright © {new Date().getFullYear()} ProFlex Pharmaceuticals. All rights reserved.</p>
          <img src={logo} alt='ProFlex Pharmaceuticals' />
        </Footer>
      </Wrapper>
      <Modal
        isOpen={isOpen}
        style={customStyles}
        contentLabel="Sucesso!"
        shouldCloseOnOverlayClick
      >
        <button type="button" onClick={() => closeModal()}><MdClose size={30}/></button>
        <img style={{ maxWidth: '300px' }} onClick={() => closeModal()} src={productModal.image} alt={productModal.name} />
        <h3>
          {productModal.name}
          <MdOutlineStar size={15} />
          <MdOutlineStar size={15} />
          <MdOutlineStar size={15} />
          <MdOutlineStar size={15} />
          <MdOutlineStar size={15} />
        </h3>
        <h4>{productModal.description}</h4>
      </Modal>
    </>
  )
}
